import { Link as GatsbyLink } from "gatsby";
import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import { Wrapper } from "../components/shared";
import { CONSTANTS } from "../constants";

export default function ErrorPage() {
  return (
    <Layout>
      <Wrapper>
        <h1>Sorry, that page was not found. </h1>
        <Link to="/">Return to homepage</Link>
      </Wrapper>
    </Layout>
  );
}

const Link = styled(GatsbyLink)`
  display: inline-block;
  margin-top: ${CONSTANTS.space[6]};
  font-size: 24px;
`;
